import React, { useState } from 'react';
import Lightbox from 'react-images';
import { withRouter } from 'react-router';
import { connectHits, Highlight } from 'react-instantsearch-dom';
import Marker from './marker';

import {
  NoResult,
  StyledHitImg,
  StyledHit,
  StyledHitContent,
  StyleName,
  StyledStats,
  StyledAddress,
  StyledDesc,
  StyledTags,
  StyledTag,
} from './hits.styles';

export const Hits = connectHits(({ hits }) => (
  <>
    {hits.length > 0 ? (
      <div>
        {hits.map((hit, idx) => (
          <div key={hit.objectID} id={hit.objectID}>
            <Hit hit={hit} number={idx + 1} highlight={true} />
          </div>
        ))}
      </div>
    ) : (
      <NoResult>
        <span>沒有結果</span>
      </NoResult>
    )}
  </>
));

export const Hit = withRouter(props => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = () => setLightboxIsOpen(true);
  const closeLightbox = () => setLightboxIsOpen(false);
  const prevImage = () => setCurrentImage(cur => cur - 1);
  const nextImage = () => setCurrentImage(cur => cur + 1);

  return (
    <StyledHit>
      <StyledHitImg onClick={openLightbox}>
        <img src={props.hit.imgs[0]} alt={props.hit.title} />
      </StyledHitImg>
      <StyledHitContent>
        {props.number && <Marker number={props.number} />}
        <StyleName to={`/f/${props.hit.objectID}`}>
          {props.highlight ? (
            <Highlight attribute="name" hit={props.hit} />
          ) : (
            <div>{props.hit.name}</div>
          )}
        </StyleName>
        <StyledStats>
          <div>{props.hit.stars}</div>
          <div>{props.hit.price}</div>
        </StyledStats>
        <StyledAddress>地址: {props.hit.address}</StyledAddress>
        <StyledDesc>
          {props.highlight ? (
            <Highlight attribute="description" hit={props.hit} />
          ) : (
            <div>{props.hit.description}</div>
          )}
        </StyledDesc>
        <StyledTags>
          {props.hit.tags.map((tag, idx) => (
            <StyledTag key={idx}>{tag}</StyledTag>
          ))}
        </StyledTags>
      </StyledHitContent>
      <Lightbox
        currentImage={currentImage}
        images={props.hit.imgs.map(img => ({ src: img }))}
        isOpen={lightboxIsOpen}
        onClose={closeLightbox}
        onClickPrev={prevImage}
        onClickNext={nextImage}
      />
    </StyledHit>
  );
});
