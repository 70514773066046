import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import {
  GoogleMapsLoader,
  GeoSearch,
  CustomMarker,
  Control,
} from 'react-instantsearch-dom-maps';

import Marker from './marker';
import mapIcon from './svg/map.svg';
import { useGlobalState } from './global-state';
import MapMount from './map-mount';
import { scrollToHit } from './utils';

import 'snazzy-info-window/dist/snazzy-info-window.min.css';

import {
  MOBILE_WIDTH,
  mapStyles,
  MapIcon,
  StyledMap,
  NotInArea,
} from './map.styles';

const Info = withRouter(({ history, hit, number }) => {
  let infowindow = null;

  useEffect(() => {
    window.mapMarkerClickhook = objectID => {
      history.push(`/f/${objectID}/`);
    };
    return () => (window.mapMarkerClickhook = undefined);
  }, []);

  const onClickMarker = marker => {
    if (document.body.clientWidth > MOBILE_WIDTH) {
      scrollToHit(hit);
    }

    const content = `
      <div class="infowindow" onclick="
        if (mapMarkerClickhook) {
          mapMarkerClickhook('${hit.objectID}')
        }
      ">
        <div class="infowindow-img">
          <img src="${hit.imgs[0]}" alt="food image"/>
        </div>
        <div class="infowindow-content">
          <div class="infowindow-name">${hit.name}</div>
          <div class="infowindow-address">${hit.address}</div>
          <div class="infowindow-stats">
            ${hit.stars || ''} ${hit.price || ''}
          </div>
        </div>
      </div>
    `;
    const SnazzyInfoWindow = require('snazzy-info-window');

    infowindow = new SnazzyInfoWindow({
      marker,
      position: marker.getPosition(),
      closeWhenOthersOpen: true,
      offset: { top: '-35px' },
      showCloseButton: false,
    });
    infowindow.setContent(content);
    infowindow.open();
  };

  return (
    <CustomMarker hit={hit} onClick={({ marker }) => onClickMarker(marker)}>
      <Marker number={number} />
    </CustomMarker>
  );
});

function ScrollToMap() {
  return (
    <MapIcon
      onClick={() => {
        const map = document.getElementById('map');
        map.scrollIntoView();
        window.scrollBy(0, -70);
      }}
    >
      地圖
      <img src={mapIcon} alt="map icon" />
    </MapIcon>
  );
}

export default function Map() {
  const [inBayarea] = useGlobalState('inBayarea');
  return (
    <>
      {!inBayarea && (
        <NotInArea>你現在的位置不在灣區地區(地圖為預設灣區位置)</NotInArea>
      )}
      <StyledMap id="map">
        <GoogleMapsLoader
          apiKey="AIzaSyCc1Yy5JdOBcpGxd719kgOIi5-tGKCLA60"
          endpoint="https://maps.googleapis.com/maps/api/js?v=weekly"
        >
          {google => (
            <>
              <GeoSearch
                google={google}
                enableRefineOnMapMove={false}
                styles={mapStyles}
              >
                {({ hits }) => (
                  <div>
                    {hits.map((hit, idx) => (
                      <Info key={hit.objectID} hit={hit} number={idx + 1} />
                    ))}
                    <Control
                      translations={{
                        control: '設為即時搜尋',
                        redo: '重新搜尋',
                      }}
                    />
                    <MapMount />
                  </div>
                )}
              </GeoSearch>
            </>
          )}
        </GoogleMapsLoader>
        <ScrollToMap />
      </StyledMap>
    </>
  );
}
