import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import DisqusThread from './disqus-thread';

import styled from 'styled-components';
import { index } from './algoliasearch';
import { Hit } from './hits';

const FoodDetailRoot = styled.div`
  width: 100%;
  max-width: 935px;
  margin: 20px auto 0;
`;

export const Container = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 50px;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Content = styled('div')`
  flex: 0 0 400px;
  max-width: 400px;

  @media (max-width: 935px) {
    flex: 0 0 350px;
    max-width: 350px;
  }

  @media (max-width: 767px) {
    flex: 1 0 350px;
    max-width: initial;
  }
`;

export const SideBar = styled('div')`
  align-self: flex-start;
  flex: 1;
  margin-left: 20px;

  @media (max-width: 767px) {
    position: static;
    margin-left: 0;
    width: 100%;
  }
`;

export const MapImg = styled('img')`
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
`;

export default function FoodDetail(props) {
  const [post, setPost] = useState(null);
  const { objectID } = props.match.params;

  useEffect(
    () => {
      async function fetchData() {
        const res = await index.getObject(objectID);
        setPost(res);
      }

      fetchData();
    },
    [objectID]
  );

  if (!post) return null;
  const mapQuery = {
    center: `${post._geoloc.lat},${post._geoloc.lng}`,
    zoom: 11,
    markers: `icon:https://calfoodie.com/favicon.png|${post._geoloc.lat},${
      post._geoloc.lng
    }`,
    size: '500x350',
    key: 'AIzaSyApV9I1VGFZ6rf4ZmoiBWOqiqN9PdYyngU',
  };
  const staticMapAPI = 'https://maps.googleapis.com/maps/api/staticmap';
  const mapSrc = `${staticMapAPI}?${queryString.stringify(mapQuery)}`;
  return (
    <FoodDetailRoot>
      <Helmet>
        <title>{post.name}</title>
        <meta name="description" content={post.description} />
        <meta property="og:title" content={post.name} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.imgs[0]} />
        <meta
          property="og:url"
          content={`https://calfoodie.com/f/${post.objectID}`}
        />
      </Helmet>
      <Container>
        <Content>
          <Hit hit={post} />
        </Content>
        <SideBar>
          <MapImg src={mapSrc} />
          <DisqusThread
            id={post.objectID}
            title={post.name}
            path={`/f/${post.objectID}`}
          />
        </SideBar>
      </Container>
    </FoodDetailRoot>
  );
}
