import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledHitImg = styled('div')`
  cursor: pointer;
  img {
    width: 100%;
  }
`;

export const StyledHitContent = styled('div')`
  padding: 20px;
  font-size: 14px;
  font-weight: 400;

  h2 {
    margin: 0;

    .ais-Highlight-nonHighlighted {
      font-size: 16px;
      font-weight: bold;
    }

    .ais-Highlight-highlighted {
      background-color: #ffe4be;
      font-style: inherit;
    }
  }

  .ais-Highlight-nonHighlighted {
    font-size: 14px;
  }
`;

export const StyledStats = styled('div')`
  margin-bottom: 10px;
`;

export const StyledAddress = styled('div')`
  margin-bottom: 10px;
`;

export const StyledDesc = styled('div')`
  margin-bottom: 10px;
`;

export const StyledTags = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTag = styled('span')`
  &:last-child {
    margin-right: 0;
  }

  margin-right: 5px;
  color: #2ba4ff;
`;

export const StyledHit = styled('div')`
  width: 100%;
  background: white;
  border: 1px solid #efefef;
  box-shadow: 0 2px 5px 0 #efefef;
  color: #262626;
  padding: 0;
  margin-bottom: 20px;
  text-decoration: inherit;
`;

export const NoResult = styled('div')`
  margin-top: 20px;
  text-align: center;

  span {
    border: 1px solid #efefef;
    border-radius: 3px;
    background: white;
    padding: 5px 10px;
  }
`;

export const StyleName = styled(Link)`
  color: #262626;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    color: #666666;
  }

  & .ais-Highlight-nonHighlighted {
    font-size: 16px;
  }
`;
