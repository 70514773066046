import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';

import App from './app';
import { GlobalStateProvider } from './global-state';

const GlobalStyle = createGlobalStyle`
  body, h1 {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    background: #fafafa;
    -webkit-font-smoothing: antialiased;
  }
`;

ReactDOM.render(
  <GlobalStateProvider>
    <GlobalStyle />
    <App />
  </GlobalStateProvider>,
  document.getElementById('root')
);

const calfoodie = `
MMMMMMMMMMMMMMMNMMMMMMNNNNNNMMMNMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMNhyo/::---...-:/oshNMMMMMMMMMMMMMMM
MMMMMMMMMMMNho:-----------.........:+yNMMMMMMMMMMM
MMMMMMMMNh+:---------------............/hNMMMMMMMM
MMMMMMNy::------------------.............-sNMMMMMM
MMMMMh/::::------------------..............-yMMMMM
MMMNo::::::::------------------............../NMMM
MMN/::::::::::------------------..............-mMM
MN/:::::::::::-------------------....ohdh+.....-NM
Mo::::::::::::::------------------../yyyyy:...../M
d//::::::::::::::------------------.-yyyys.......y
o///::::::::::::::-----------------....-........./
/////::::::::::::::------------------.............
//////::::::::::::::------------------..........:+
///////::::::::::::::------------------....:+sdNMM
o////////:::::::::::::-----------------://+smMMMMM
d/////////:::::::::::::-------------:+++++++omMMMM
Mo/////////:::::::::::::----------------://++yMMMM
MN+/////////:::::::::::::------------------.-:/yMM
MMN+/////////::::::::::::::-----------------..:mMM
MMMNs/////////:::::::::::::------------------+NMMM
MMMMMh+////////::::::::::::::--------------:yMMMMM
MMMMMMMh+///////::::::::::::::-----------:yNMMMMMM
MMMMMMMMMdo//////::::::::::::::--------+hNMMMMMMMM
MMMMMMMMMMMNhs+///:::::::::::::----:ohNMMMMMMMMMMM
MMMMMMMMMMMMMMMNdyso+/:::::://+oydNMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMNMMMMMMNNNNNNMMMNMMMMMMMMMMMMMMMMMM
`;

// eslint-disable-next-line no-console
console.log(calfoodie);
