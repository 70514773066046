import React from 'react';

import { InstantSearch, PoweredBy } from 'react-instantsearch-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { Helmet } from 'react-helmet';

import { useGlobalState } from './global-state';
import { Hits, Hit } from './hits';

import mediumSvg from './svg/medium.svg';
import facebookSvg from './svg/facebook.svg';
import instagramSvg from './svg/instagram.svg';
import calfoodieSvg from './svg/calfoodie.svg';

import Map from './map';
import { searchClient, indexName } from './algoliasearch';
import SearchConfigure from './configure';
import { mapInstance } from './map-mount';
import FoodDetail from './food-detail';

import {
  Root,
  NavbarWrapper,
  Navbar,
  Logo,
  SocialLink,
  Container,
  Content,
  SideBar,
  SearchBoxWrapper,
  StyledSearchBox,
  StyledPagination,
  StyledRefinementList,
} from './app.styles';

function Landing() {
  const [dynamicSearchConfig, setSearchConfig] = useGlobalState(
    'dynamicSearchConfig'
  );
  const [searchState, setSearchState] = useGlobalState('searchState');

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={searchState}
      onSearchStateChange={state => {
        setSearchState(state);
      }}
    >
      <Helmet>
        <title>Calfoodie 加州美食地圖</title>
        <meta
          name="description"
          content="Calfoodie 用圖片和文字把灣區各式各樣的生活及美食記錄了下來，做成了美食地圖。以地圖搜尋加州、灣區各地美食。"
        />
        <meta property="og:title" content="Calfoodie x 加州美食地圖" />
        <meta
          property="og:description"
          content="Calfoodie 用圖片和文字把灣區各式各樣的生活及美食記錄了下來，美食地圖因此而誕生 :)"
        />
        <meta
          property="og:image"
          content="https://calfoodie.com/calfoodie-256.png"
        />
        <meta property="og:url" content="https://calfoodie.com" />
      </Helmet>
      <Container>
        <Content>
          <Hits hitComponent={Hit} />
        </Content>
        <SideBar>
          <SearchBoxWrapper>
            <StyledSearchBox
              searchAsYouType={false}
              translations={{
                placeholder: '',
              }}
              onReset={e => {
                e.preventDefault();
                const bounds = mapInstance.getBounds();
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                setSearchConfig({
                  query: '',
                  insideBoundingBox: `${ne.lat()}, ${ne.lng()}, ${sw.lat()}, ${sw.lng()}`,
                });
              }}
              onSubmit={e => {
                e.preventDefault();
                e.target[0].blur();
                const bounds = mapInstance.getBounds();
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                setSearchConfig({
                  query: e.target[0].value,
                  insideBoundingBox: `${ne.lat()}, ${ne.lng()}, ${sw.lat()}, ${sw.lng()}`,
                });
                return false;
              }}
            />
            <PoweredBy />
          </SearchBoxWrapper>
          <StyledRefinementList attribute="tags" />
          <Map />
          <StyledPagination
            padding={2}
            defaultRefinement={dynamicSearchConfig.page}
          />
        </SideBar>
        <SearchConfigure />
      </Container>
    </InstantSearch>
  );
}

const App = () => (
  <Router>
    <Root>
      <NavbarWrapper>
        <Navbar>
          <Logo to="/">
            <img src={calfoodieSvg} alt="logo" />
            <h1>Calfoodie</h1>
            <h2>加州美食地圖</h2>
          </Logo>
          <SocialLink>
            <a href="https://medium.com/calfoodie" target="__blank">
              <img src={mediumSvg} alt="medium logo" />
            </a>
            <a href="https://www.facebook.com/calfoodie" target="__blank">
              <img src={facebookSvg} alt="facebook logo" />
            </a>
            <a href="https://instagram.com/cal_foodie" target="__blank">
              <img src={instagramSvg} alt="instagram logo" />
            </a>
          </SocialLink>
        </Navbar>
      </NavbarWrapper>
      <Switch>
        <Route path="/f/:objectID/" exact component={FoodDetail} />
        <Route path="/" component={Landing} />
      </Switch>
    </Root>
  </Router>
);

export default App;
