import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  inBayarea: true,
  dynamicSearchConfig: {},
  searchState: {},
};
const { GlobalStateProvider, useGlobalState } = createGlobalState(initialState);

export { GlobalStateProvider, useGlobalState };
