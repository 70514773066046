import React from 'react';
import PropTypes from 'prop-types';
export let mapInstance = null;

export default class MapMount extends React.Component {
  static contextTypes = {
    // eslint-disable-next-line camelcase
    __ais_geo_search__google_maps__: PropTypes.shape({
      google: PropTypes.object,
      instance: PropTypes.object,
    }),
  };

  componentDidMount() {
    const { instance: map } = this.context.__ais_geo_search__google_maps__;
    mapInstance = map;
  }

  componentWillUnmount() {
    mapInstance = null;
  }

  render() {
    return null;
  }
}
