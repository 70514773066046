import React from 'react';
import marker from './svg/marker.svg';
import styled from 'styled-components';

const StyledMarker = styled('div')`
  position: relative;
  width: 22px;
  img {
    width: 22px;
  }

  div {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: white;
  }
`;

export default function Marker({ number }) {
  return (
    <StyledMarker>
      <img src={marker} alt="marker" />
      <div>{number}</div>
    </StyledMarker>
  );
}
