import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Configure } from 'react-instantsearch-dom';
import { useGlobalState } from './global-state';

const bayAreaBoundingBox = {
  northEast: {
    lat: 37.903477,
    lng: -121.710075,
  },
  southWest: {
    lat: 37.225937,
    lng: -122.7452,
  },
};

function SearchConfigure() {
  const [dynamicSearchConfig, setSearchConfig] = useGlobalState(
    'dynamicSearchConfig'
  );

  const [, setInBayarea] = useGlobalState('inBayarea');

  React.useEffect(() => {
    if (!isEmpty(dynamicSearchConfig)) {
      return;
    }

    setSearchConfig({
      insideBoundingBox: `${bayAreaBoundingBox.northEast.lat}, ${
        bayAreaBoundingBox.northEast.lng
      },  ${bayAreaBoundingBox.southWest.lat}, ${
        bayAreaBoundingBox.southWest.lng
      }`,
    });

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        if (
          lat > bayAreaBoundingBox.northEast.lat + 0.5 ||
          lat < bayAreaBoundingBox.southWest.lat - 0.5 ||
          lng > bayAreaBoundingBox.northEast.lng + 0.5 ||
          lng < bayAreaBoundingBox.southWest.lng - 0.5
        ) {
          setInBayarea(false);
          return;
        }
        setSearchConfig({
          insideBoundingBox: `${lat + 0.01}, ${lng + 0.03}, ${lat -
            0.01}, ${lng - 0.03}`,
        });
      });
    }
  }, []);

  return (
    <Configure
      hitsPerPage={10}
      analytics={false}
      distinct
      {...dynamicSearchConfig}
    />
  );
}

export default SearchConfigure;
