import { SearchBox, Pagination, RefinementList } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;

  .ais-InstantSearch__root {
    display: flex;
    width: 100%;
    max-width: 935px;
    margin: 20px auto 0;
  }
`;

export const NavbarWrapper = styled('div')`
  display: flex;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 10px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
`;

export const Navbar = styled('header')`
  width: 910px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Container = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 50px;
  padding: 0 20px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled('div')`
  flex: 0 0 400px;
  max-width: 400px;

  @media (max-width: 935px) {
    flex: 0 0 350px;
    max-width: 350px;
  }

  @media (max-width: 767px) {
    flex: 1 0 350px;
    max-width: initial;
  }
`;

export const SideBar = styled('div')`
  align-self: flex-start;
  flex: 1;
  top: 70px;
  position: sticky;
  margin-left: 20px;

  @media (max-width: 767px) {
    position: static;
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledPagination = styled(Pagination)`
  margin: 20px;
  font-weight: 300;

  .ais-Pagination-link {
    border: 1px solid #efefef;
    box-shadow: 0 2px 5px 0 #efefef;
    color: #878787;
    background: white;

    &:hover {
      background: #efefef;
    }
  }

  .ais-Pagination-link--selected {
    background: #545454;
    color: white;

    &:hover {
      background: #434343;
    }
  }
`;

export const SearchBoxWrapper = styled('div')`
  margin: 0 0 20px;

  .ais-PoweredBy {
    color: #262626;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }
`;

export const StyledSearchBox = styled(SearchBox)`
  input {
    color: #262626;
    border: 1px solid #efefef;
    box-shadow: 0 2px 5px 0 #efefef;
    height: 40px;
    padding-left: 30px;
    border-radius: 0;
  }

  input:focus {
    outline: none;
  }

  .ais-SearchBox-submit {
    left: 0.5rem;
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  img {
    max-height: 30px;
    margin-right: 10px;
  }

  h1 {
    font-size: 18px;
    margin: 0;
  }

  h2 {
    font-size: 14px;
    margin: 0;
    color: #585858;
    margin-left: 7px;
  }
`;

export const SocialLink = styled('div')`
  margin-left: auto;
  display: flex;

  img {
    height: 25px;
    margin-left: 10px;
  }
`;

export const StyledRefinementList = styled(RefinementList)`
  margin: 0 0 20px;
  .ais-RefinementList-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ais-RefinementList-item {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 5px;
    border: 2px solid #d3ecff;
    border-radius: 20px;
    padding: 0px 3px 0 6px;
    line-height: 18px;
  }

  .ais-RefinementList-labelText {
    cursor: pointer;
    font-size: 14px;
    color: #2ba4ff;
  }

  .ais-RefinementList-count {
    cursor: pointer;
    color: #2ba4ff;
    background: #e1f2ff;
    font-size: 12px;
    padding: 0px 4px;
  }

  .ais-RefinementList-checkbox {
    display: none;
  }

  .ais-RefinementList-item--selected {
    font-weight: bold;
  }

  .ais-RefinementList-item--selected {
    border-color: #2ba4ff;
    background: #2ba4ff;

    .ais-RefinementList-labelText {
      color: white;
    }

    .ais-RefinementList-count {
      display: none;
    }

    .ais-RefinementList-labelText:after {
      content: 'x';
      margin: 0 3px 0 5px;
      color: white;
    }

    &:hover {
      border-color: #228ad8;
      background: #228ad8;
    }
  }
`;
