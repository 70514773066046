import styled from 'styled-components';

export const MOBILE_WIDTH = 767;
export const StyledMap = styled('div')`
  height: 400px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    height: 350px;
  }

  .si-content-wrapper {
    cursor: pointer;
    padding: 0;
    border: none !important;
  }

  .ais-GeoSearch-label {
    color: #262626;
  }

  .ais-GeoSearch-redo {
    background: #545454;
    color: white;

    &:hover {
      background: #434343;
    }
  }

  .infowindow {
    display: flex;
    height: 80px;
    max-width: 270px;
    color: #262626;
    font-weight: 500;
    background: white;
    overflow: hidden;

    .infowindow-img {
      background: black;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        opacity: 0.8;
      }
    }

    .infowindow-content {
      margin: 10px;
      white-space: normal;
      overflow: hidden;
    }

    .infowindow-name {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .infowindow-address {
      font-size: 10px;
      line-height: 12px;
      font-weight: 300;
      color: #545454;
      margin-bottom: 2px;
    }

    .infowindow-stats {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const MapIcon = styled('div')`
  cursor: pointer;
  position: fixed;
  background: white;
  right: 30px;
  bottom: 15px;
  border: 1px solid #efefef;

  display: flex;
  align-items: center;
  padding: 5px 10px;

  border-radius: 5px;
  transition: all 0.3s;

  img {
    width: 25px;
    margin-left: 5px;
  }

  :hover {
    box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.3);
  }

  @media (min-width: ${MOBILE_WIDTH + 1}px) {
    display: none;
  }
`;

export const NotInArea = styled('div')`
  opacity: 0.6;
  margin-bottom: 10px;
  font-size: 12px;
`;

export const mapStyles = [
  {
    featureType: 'landscape',
    stylers: [
      {
        hue: '#FFBB00',
      },
      {
        saturation: 43.400000000000006,
      },
      {
        lightness: 37.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 51.19999999999999,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        hue: '#00FF6A',
      },
      {
        saturation: -1.0989010989011234,
      },
      {
        lightness: 11.200000000000017,
      },
      {
        gamma: 1,
      },
    ],
  },
];
